import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Header } from '../Header';
import { BackButton } from "../Button/Button";
import './styles.css';

const Container = styled.div`
font-family: 'Roboto', sans-serif;
`;

const LinesImage = styled.img`
    height: 13em;
`;

const RulesContainerWithLinesImage = styled.div`
    display: flex;
    flex-direction: row;
`;

const RulesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-centent: center;
    padding: 0 4em;
    margin-top: 2.5em;
`;

const RulesAndRegulations = (props) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulRulesPageContent {
                nodes {
                  title
                  rulesAndRegulationsGr {
                    raw
                  }
                  rulesAndRegulations {
                    raw
                  }
                  leftLinesImage {
                    file {
                      url
                    }
                    title
                  }
                }
              }
        }
    `);

    const pageContent = data.allContentfulRulesPageContent.nodes[0];
    const rulesContent = (props.lang === '' || props.lang === 'En-gb') ? pageContent.rulesAndRegulations.raw : pageContent.rulesAndRegulationsGr.raw
    const title = (props.lang === '' || props.lang === 'En-gb') ? pageContent.title.toUpperCase() : 'ΓΕΝΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ, ΟΡΟΙ ΚΑΙ ΚΑΝΟΝΙΣΜΟΙ';
    return (
        <Container>
            <BackButton text="Back" />
            <Helmet>
                <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
            </Helmet>
            <Header>{title}</Header>
            <RulesContainerWithLinesImage>
                <LinesImage src={pageContent.leftLinesImage.file.url} />
                <RulesContainer>
                    {documentToReactComponents(JSON.parse(rulesContent))}
                </RulesContainer>
            </RulesContainerWithLinesImage>
        </Container>
    );
}

export default RulesAndRegulations;